.topNav {
  background: linear-gradient(#000000, #434343);
}

.hero {
  background: linear-gradient(90deg, #de6161 0.25%, #2657eb 100%);
  min-height: 70vh;
}

.hero img {
  max-width: 400px;
}

.cont {
  background: rgb(14, 33, 212);
  width: 330px;
  color: rgb(255, 255, 255);
}
.img {
  border-radius: 40%;
}
.nav-link:hover {
  font-size: 2rem;
}
/* title */
.title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-top: 4rem;
  letter-spacing: 0.3rem;
  margin-bottom: 4rem;
}

.title span {
  border-bottom: 5px solid yellowgreen;
  padding: 0.5rem 1rem;
}

/* icons */
.icons {
  font-size: 2rem;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.icons div {
  margin-bottom: 2rem;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icons i {
  font-size: 5rem;
}

i.fa-figma {
  background: linear-gradient(
    180deg,
    #ee1414 0%,
    #d012d3 38.02%,
    #120eb6 66.15%,
    #14b70e 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

/* Project section */
.projects {
  background: rgba(57, 221, 28, 0.41);
}
.gitlogo:hover {
  font-size: 3rem;
}
.contact {
  background: rgba(153, 205, 50, 0.548);
  margin-top: 15rem;
}

#goup {
  position: fixed;
  bottom: 30px;
  right: 20px;
  font-size: 3rem;
}
.main-layout {
  min-height: 73vh;
}
.containe {
  background: rgb(79, 85, 119);
  padding-top: 5px;
}
#root {
  background: linear-gradient(-45deg, #ee7752, #23a6d5, #23d5ab, yellow);
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
